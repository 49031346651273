// i18next-extract-mark-ns-start omnichannel-payments-platform
import { Button } from "components/Button";
import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { HomePaymentMethods } from "components/HomePaymentMethods";
import { Section, SectionActions, SectionHeader, SectionImage } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

//Images
import omnichannel from "images/omnichannel.svg";
import site_template from "images/site_template.svg";
import coins from "images/coins.svg";
import coinstack from "images/coinstack.svg";
import megaphone from "images/megaphone.svg";
import person from "images/person.svg";
import payment from "images/payment.svg";
import heart_box from "images/heart_box.svg";
import mobile_payment from "images/mobile_payment.svg";
import monei_pay_form_en from "images/monei_pay_form_en.png";
import monei_pay_form_es from "images/monei_pay_form_es.png";

//Testimonial logos
//Partners
import React from "react";
import styled from "styled-components";
import CardsContainer from "components/CardsContainer";
import { Card, CardImage, CardWrapper } from "components/Card";
import { GradientBox, GradientCtaImage, GradientSectionDescription } from "components/landings/GradientBox";
import { AnchorLink } from "components/AnchorLink";
import { StyledDiv } from "components/StyledSx";
import { ScreenSizes } from "../../types/responsive";
import crane from "images/crane.svg";
import { BlogLink } from "components/links/Blog";
import { InternalPageLink, MoneiPayLink, PricingLink } from "components/links/Pages";
import { IosDownloadLink } from "components/DownloadOnAppleStore";
import { AndroidDownloadLink } from "components/DownloadOnGooglePlay";
import { Background } from "components/Background";
import { LanguageEnum } from "../../locales/types";

const CoinsContainer = styled.div`
  position: relative;
  margin-top: 45px;
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
  img {
    position: relative;
    width: 75px;
  }
  .coins_1 {
    top: 0;
    left: 0;
  }
  .coins_2 {
    top: 75px;
    left: 0;
  }
`;

const IndexBackground = styled.div`
  position: relative;

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 236, -291);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 1500px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 8.43%, #aab4e9 38.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 52%;
  top: 30px;
  width: 550px;
  z-index: 9;

  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
  }
`;

const PaymentCard = styled(Card)`
  padding: 32px 62px;
  flex-basis: 100%;
  margin-top: -60px;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 40px 32px;
  }
`;

const OmnichannelPaymentsPlatform: React.FC = () => {
  const {navigate, t, language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const monei_pay_form = isSpanish ? monei_pay_form_es : monei_pay_form_en;
  return (
    <>
      <SEO
        title={t('Omnichannel Payments Platform')}
        description={t(
          'Omnichannel sales require a connected payment solution that streamlines the customer experience across channels. Use MONEI’s omnichannel payments platform ››'
        )}
      />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Omnichannel payments platform</Trans>
            </SectionHeader>
            <Trans parent="p">
              A strong omnichannel strategy means streamlining the buying experience across all
              channels whether shoppers are in-store or online. Your omnichannel sales and marketing
              strategy is crucial, but a key factor that’s often overlooked is payments. Unify your
              online and in-store payments with an omnichannel payments platform that’s already used
              by hundreds of South Europe’s most successful brands.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <CoinsContainer>
              <img src={coins} className="coins_1" alt="MONEI coins" />
              <img src={coins} className="coins_2" alt="MONEI coins" />
            </CoinsContainer>
          </IndexContent>
        </Content>
        <IndexImage
          src={omnichannel}
          alt="MONEI payment gateway"
          title="MONEI payment gateway"
          className="hide-on-mobile"
        />
      </IndexBackground>
      <Background>
        <Content>
          <Section centered>
            <SectionImage
              src={crane}
              alt="Shopify payments orchestration"
              title="Shopify payments orchestration"
              width={600}
              height={400}
              mobileWidth={280}
              className="hide-on-mobile"
            />
            <div>
              <SectionHeader>
                <Trans>Why build an omnichannel strategy for your retail business?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Unlike a multi-channel approach, omnichannel means all aspects of your business are
                connected. From online to in-store inventory, marketing campaigns, customer service,
                and accepting e-commerce and physical payments, everything is linked.
              </Trans>
              <Trans parent="p">
                Customers have multiple touchpoints before buying, so offering a consistent
                experience across their chosen channels, location, and time is vital. Especially
                when it’s time to provide their payment details.
              </Trans>
              <Trans parent="p">
                Whether shoppers are paying from a desktop, mobile device, or in your brick and
                mortar store, acquiring and keeping loyal customers requires a frictionless and
                secure payment experience.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content>
        <Section centered textAlign="center">
          <div>
            <SectionHeader>
              <Trans>Advantages of using an omnichannel payments platform</Trans>
            </SectionHeader>
            <Trans parent="p">
              Omnichannel payment solutions come with many benefits for your online and offline
              business.
            </Trans>
            <CardsContainer style={{marginTop: '60px'}}>
              <Card>
                <CardImage src={payment} alt="" style={{top: -50}} />
                <CardWrapper>
                  <Trans>Offering a unified payment experience everywhere and at any time</Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={mobile_payment} alt="" style={{top: -47, width: '130px'}} />
                <CardWrapper>
                  <Trans parent="p">
                    Accepting <BlogLink slug="alternative-payment-methods">alternative</BlogLink>
                    and <BlogLink slug="local-payment-methods">local payment methods</BlogLink> in
                    both online and physical stores
                  </Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={coinstack} alt="" style={{width: '90px', top: -40}} />
                <CardWrapper>
                  <Trans parent="p">Saving time and money on third-party integrations</Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={site_template} alt="" style={{width: '120px', top: -62}} />
                <CardWrapper>
                  <Trans>Centralizing payment information in a single dashboard</Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={person} alt="" style={{width: '105px', top: -68}} />
                <CardWrapper>
                  <Trans parent="p">Getting a holistic view of each customer</Trans>
                </CardWrapper>
              </Card>
              <Card>
                <CardImage src={megaphone} alt="" style={{width: '110px', top: -50}} />
                <CardWrapper>
                  <Trans parent="p">
                    Personalizing the payment process by collecting payment data across channels
                  </Trans>
                </CardWrapper>
              </Card>
              <PaymentCard>
                <HomePaymentMethods />
                <Button variant="dark" onClick={() => navigate('/payment-methods/')}>
                  <Trans>View All Payment Methods</Trans>
                </Button>
              </PaymentCard>
            </CardsContainer>
          </div>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section>
            <SectionImage
              src={heart_box}
              alt="Shopify payments orchestration"
              title="Shopify payments orchestration"
              width={600}
              height={450}
              mobileWidth={280}
              className="hide-on-mobile"
            />
            <StyledDiv>
              <SectionHeader>
                <Trans>Boost customer satisfaction with MONEI’s omnichannel payment solution</Trans>
              </SectionHeader>
              <Trans parent="p">
                Manage your <BlogLink slug="omnichannel-payments">omnichannel payments</BlogLink> with MONEI to accept a{' '}
                <InternalPageLink slug="payment-methods">wide range of payment methods</InternalPageLink>{' '}
                and manage it all (online and off) from one platform.
              </Trans>
              <Trans parent="p">
                <AnchorLink href="https://docs.monei.com/docs/">Integrate</AnchorLink> with your
                e-commerce website for{' '}<InternalPageLink slug="online-payments">online payments</InternalPageLink> and use{' '}
                <MoneiPayLink>MONEI Pay</MoneiPayLink> to take contactless in-store (or on the go){' '}
                <BlogLink slug="qr-code-payments">QR code payments</BlogLink>. Easily and affordably
                equip your staff to collect mobile payments, view transaction history anytime, avoid
                customer queues, and <PricingLink>save up to 50%</PricingLink> on POS hardware and
                transaction fees.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </StyledDiv>
          </Section>
        </Content>
      </Background>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}} sx={{paddingTop: '80px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Power your omnichannel payments with MONEI</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Get started today (without obligation) and download your mobile payment app on one or
              many <IosDownloadLink>iOS</IosDownloadLink> or{' '}
              <AndroidDownloadLink>Android</AndroidDownloadLink> mobile devices.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage src={monei_pay_form} />
        </GradientBox>
      </Content>
    </>
  );
};

export default OmnichannelPaymentsPlatform;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "omnichannel-payments-platform"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
